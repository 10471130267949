.video{
    min-height: 100vh;
}
.video .swiper-button-next, 
.video .swiper-button-prev {
  color: #fff;
}
.video .swiper-button-next:after, 
.video .swiper-button-prev:after {
    font-size: 20px;
}
.modal-backdrop .modal-close {
    right: 0;
    top: 40px;
}
.modal-backdrop{
    z-index: 9999999;
    background-color: black;
}
/* .video .main-video{
    max-height: 100vh;
    min-height: 100vh;
    height: 100vh;
}
.video-react .video-react-big-play-button {
    top: 50% !important;
    left: 50% !important;
} */