body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}
*{
  padding: 0;
  margin: 0;
  
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.flash-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: #f0f0f0;
}
.flash-body .audio{
  opacity: 0;
}
.camera-flash {
  width: 100px;
  height: 100px;
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  animation: flash 0.5s infinite;
}

@keyframes flash {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  25% {
    opacity: 0.7;
    transform: scale(2);
  }
  50% {
    opacity: 0.7;
    transform: scale(3);
  }
  75% {
    opacity: 0;
    transform: scale(4);
  }
  100% {
    opacity: 0;
    transform: scale(5);
  }
}
/* Modal.css */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.modal-content {
  padding: 20px;
  border-radius: 8px;
  width: 75%;
  max-width: 90%;
}

.modal-close {
  position: absolute;
  color: #fff;
  top: 20px;
  right: 40px;
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
}