.header {
    position: fixed;
    background-color: #0000002e;
    z-index: 9;
}
.header .mil-banner-content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    width: auto;
    padding: 0;
}
.header .mil-banner-content h1{
    margin: 0;
}
.mobile-hidden{
    display: block;
}
@-webkit-keyframes slideUp {
    0% {
        -webkit-transform: translate3d(0,0%,0) rotate(0.0001deg);
        -moz-transform: translate3d(0,0%,0) rotate(0.0001deg);
        -ms-transform: translate3d(0,0%,0) rotate(0.0001deg);
        transform: translate3d(0,0%,0) rotate(0.0001deg)
    }

    95% {
        -webkit-transform: translate3d(0,-55%,0) rotate(0.0001deg);
        -moz-transform: translate3d(0,-55%,0) rotate(0.0001deg);
        -ms-transform: translate3d(0,-55%,0) rotate(0.0001deg);
        transform: translate3d(0,-55%,0) rotate(0.0001deg);
        opacity: 1;
        filter: blur(0px)
    }

    97% {
        -webkit-transform: translate3d(0,-55%,0) rotate(0.0001deg);
        -moz-transform: translate3d(0,-55%,0) rotate(0.0001deg);
        -ms-transform: translate3d(0,-55%,0) rotate(0.0001deg);
        transform: translate3d(0,-55%,0) rotate(0.0001deg);
        opacity: 1;
        filter: blur(0px)
    }

    98% {
        opacity: .01;
        filter: blur(5px);
        -webkit-transform: translate3d(0,-100%,0) rotate(0.0001deg);
        -moz-transform: translate3d(0,-100%,0) rotate(0.0001deg);
        -ms-transform: translate3d(0,-100%,0) rotate(0.0001deg);
        transform: translate3d(0,-100%,0) rotate(0.0001deg)
    }

    99% {
        -webkit-transform: translate3d(0,0%,0) rotate(0.0001deg);
        -moz-transform: translate3d(0,0%,0) rotate(0.0001deg);
        -ms-transform: translate3d(0,0%,0) rotate(0.0001deg);
        transform: translate3d(0,0%,0) rotate(0.0001deg)
    }

    100% {
        opacity: 1;
        filter: blur(0px)
    }
}
@-webkit-keyframes fadeIn {
    0% {
        opacity: .01;
    }

    100% {
        opacity: 1;
    }
}
@keyframes fadeIn {
    0% {
        opacity: .01;
    }

    100% {
        opacity: 1;
    }
}
@keyframes slideUp {
    0% {
        -webkit-transform: translate3d(0,0%,0) rotate(0.0001deg);
        -moz-transform: translate3d(0,0%,0) rotate(0.0001deg);
        -ms-transform: translate3d(0,0%,0) rotate(0.0001deg);
        transform: translate3d(0,0%,0) rotate(0.0001deg)
    }

    95% {
        -webkit-transform: translate3d(0,-55%,0) rotate(0.0001deg);
        -moz-transform: translate3d(0,-55%,0) rotate(0.0001deg);
        -ms-transform: translate3d(0,-55%,0) rotate(0.0001deg);
        transform: translate3d(0,-55%,0) rotate(0.0001deg);
        opacity: 1;
        filter: blur(0px)
    }

    97% {
        -webkit-transform: translate3d(0,-55%,0) rotate(0.0001deg);
        -moz-transform: translate3d(0,-55%,0) rotate(0.0001deg);
        -ms-transform: translate3d(0,-55%,0) rotate(0.0001deg);
        transform: translate3d(0,-55%,0) rotate(0.0001deg);
        opacity: 1;
        filter: blur(0px)
    }

    98% {
        opacity: .01;
        filter: blur(5px);
        -webkit-transform: translate3d(0,-100%,0) rotate(0.0001deg);
        -moz-transform: translate3d(0,-100%,0) rotate(0.0001deg);
        -ms-transform: translate3d(0,-100%,0) rotate(0.0001deg);
        transform: translate3d(0,-100%,0) rotate(0.0001deg)
    }

    99% {
        -webkit-transform: translate3d(0,0%,0) rotate(0.0001deg);
        -moz-transform: translate3d(0,0%,0) rotate(0.0001deg);
        -ms-transform: translate3d(0,0%,0) rotate(0.0001deg);
        transform: translate3d(0,0%,0) rotate(0.0001deg)
    }

    100% {
        opacity: 1;
        filter: blur(0px)
    }
}
.main-animate{
    position: fixed;
    display: block;
    z-index: 5;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    animation: fadeIn 2s ease-in-out 5s;
    animation-fill-mode: forwards;
    visibility: visible !important;
}
.grid-container{
    overflow: visible;
    /* position: fixed; */
    background: #000;
    /* top: 0;
    left: 0; */
    /* display: grid; */
    /* gap: 10px; */
    transform: translateY(0%);
    /* -webkit-animation: slideUp 90s linear infinite;
    animation: slideUp 90s linear infinite; */
}
.home-animate{
    animation: slideUp 90s linear infinite 5s;
    -webkit-animation: slideUp 90s linear infinite 5s;
}
.mil-frame {
    /* height: 60px; */
    max-height: 100vh;
    height: 100vh;
    position: fixed;
}
@keyframes slideUpMobile {
    0% {
        -webkit-transform: translate3d(0,0%,0) rotate(0.0001deg);
        -moz-transform: translate3d(0,0%,0) rotate(0.0001deg);
        -ms-transform: translate3d(0,0%,0) rotate(0.0001deg);
        transform: translate3d(0,0%,0) rotate(0.0001deg)
    }
    100% {
        -webkit-transform: translate3d(0,-100%,0) rotate(0.0001deg);
        -moz-transform: translate3d(0,-100%,0) rotate(0.0001deg);
        -ms-transform: translate3d(0,-100%,0) rotate(0.0001deg);
        transform: translate3d(0,-100%,0) rotate(0.0001deg);
    }
}
@media (max-width: 500px) {
    .mobile-hidden{
        display: none;
    }
    .header .mil-banner-content{
        top: 45%;
    }
    .home-animate{
        animation-name: slideUpMobile;
        animation-duration: 160s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-direction: alternate;

        -webkit-animation-name: slideUpMobile;
        -webkit-animation-duration: 160s;
        -webkit-animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-direction: alternate;
    }
    .mil-menu-btn {
        transform: scale(1);
        display: flex;
        z-index: 2;
    }
    .mil-banner-content img{
        width: 300px!important;
    }
    .mil-frame .mil-frame-top .dropdown .dropdown-basic{
        background: none;
        border: none;
    }
    .mil-main-menu {
        align-items: flex-start;
        padding-top: 4rem;
    }
    .mil-main-menu ul {
        align-items: start;
    }
    .mil-menu-frame{
        width: 60%;
        top: 60px;
    }
    .home-power{
        bottom: 185px!important;
        left: -12px;
        font-size: 12px;
    }
    .header{
        top: 0
    }
    .mil-frame .mil-frame-top {
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
        background: #000;
        border-bottom: none;
        display: flex;
        height: 60px;
        padding: 15px 30px;
    }
    .mil-frame .mil-frame-bottom {
        display: block;
        position: fixed;
        bottom: 110px;
        left: 25px;
    }
    .mil-frame .mil-frame-bottom .mil-social {
        /* transform: rotate(-90deg) translateX(150px) translateY(-105px); */
        /* transform: rotate(-90deg) translateX(-150%) translateY(-105px); */
    }
}