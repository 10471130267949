.appointment{
    padding-top: 8rem;
    color: #fff;
    min-height: 100vh;
}
.appointment img{
    border: 1px solid #fff;
    border-radius: 5px;
    height: 100%;
}
.appointment .title{
    color: #fff;
}
.appointment .form{
    margin-top: 2rem;
}
.appointment .form label{
    display: block;
    margin-bottom: 8px;
}
.appointment .form select{
    width: 100%;
    padding: 8px;
}
.appointment .form button{
    padding: 8px 16px;
    margin-top: 1rem;
    cursor: pointer;
    border: none;
}
.appointment .time.active{
    background-color: #fff;
    color: #000;
}
.appointment .time{
    border: 1px solid #fff;
    margin: 0;
    font-weight: 600;
    padding: 11px 22px;
    text-align: center;
    cursor: pointer;
}
.appointment .impossible{
    cursor: not-allowed;
    background: #666;
}
@media (max-width: 500px){
    .times{
        display: grid !important;
        grid-template-columns: auto auto auto;
    }
    .appointment button{
        background-color: #efefef;
        border: none;
        border-radius: 3px;
        color: #000;
    }
}