.gallary{
    min-height: 100vh;
}
.gallary img{
    width: 100%;
}
.gallary .swiper{
    border-bottom: 1px solid #fff;
}
.tab{
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    padding: 12px;
    font-weight: 500;
    margin: 0;
    text-wrap: nowrap;
}
.tab.active{
    background-color: #fff;
    color: #000;
    border-radius: 8px 8px 0 0;
}