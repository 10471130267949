.home .slick-slide img {
    width: 120px !important;
}
@media (max-width: 600px){
    .home .slick-slide{
        width: 100px !important;
    }
    .branding .slick-slider .slick-list {
        height: 50vh !important;
    }
    .home .slick-slide img {
        width: 75px !important;
    }
    .branding .slick-track{
        height: 100% !important;
    }
    .branding .slick-slide div:first-child{
        height: 100% !important;
    }
    .branding .sl-title {
        font-size: 40px;
    }
    .branding .scroll-down-button .fa-angle-down {
        bottom: -20px;
        font-size: 33px;
    }
    
    .branding .slick-next:before, .branding .slick-prev:before {
        font-size: 28px;
    }
    .branding .title {
        font-size: 24px;
        line-height: 31px;
    }
    .branding .portfolio .portfolio-filter .all-iso-item {
        font-size: 40px;
        line-height: 30px;
    }
}
.navbar-fixed{
    color: #fff;
    font-size: 22px;
    font-weight: 600;
    gap: 22px;
    display: flex;
}
.mil-social{
    /* animation-name: ShowAnimation;
    animation-duration: 1s;
    animation-delay: 5s; */
}
/* @keyframes ShowAnimation {
    0% {
        transform: translateX(200px) translateY(-130px) rotate(-90deg);
    }
    100% {
        transform: translateX(140px) translateY(-130px) rotate(-90deg);
    }
} */

.tabs{
    display: flex;
    justify-content: space-between;

}
.tabs .tab{
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    padding: 12px;
    font-weight: 500;
    margin: 0;
}
.tabs .tab.active{
    background-color: #fff;
    color: #000;
    border-radius: 8px 8px 0 0;
}
#swupMainm{
    /* min-height: 100vh; */
}
@media (max-width: 500px) {
    .tabs{
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 10px;
    }
    .tabs .tab.active{
        border-radius: 8px;
    }
    #swupMainm{
        min-height: calc(100vh - 60px);
        /* max-height: calc(100vh - 60px); */
        /* height: calc(100vh - 60px); */
    }
}
.custom-loader {
    width: 40px;
    aspect-ratio: 1;
     --c: linear-gradient(#fff 0 0);
     --m: radial-gradient(farthest-side,#fff 92%,#fff);
    background: 
      var(--m) center               /12px 12px,
      var(--c) left 50% top    -20px/8px 16px, 
      var(--c) left 50% bottom -20px/8px 16px, 
      var(--c) top  50% left   -20px/16px 8px, 
      var(--c) top  50% right  -20px/16px 8px;
    background-repeat: no-repeat;
    animation: 
      l181 1.5s infinite,
      l182 1.5s infinite;
  }
  @keyframes l181 {
    30%,
    70% {background-position: 
          center,
          left 50% top    calc(50% - 8px),
          left 50% bottom calc(50% - 8px),
          top  50% left   calc(50% - 8px),
          top  50% right  calc(50% - 8px)}
  }
  @keyframes l182 {
    0%,40%   {transform: rotate(0)}
    60%,100% {transform: rotate(90deg)}
  }
  .dropdown-item.active{
    background-color: #dedede;
  }
.rtl{
    direction: rtl;
}
.ltr{
    direction: ltr;
}
#about .swiper-button-prev,
#about .swiper-button-next{
    display: none;
}
#about .swiper-wrapper{
    text-align: center;
}
#about .cus * {
    color: rgba(255, 255, 255, 0.9) !important;
    background: none!important;
}
.cursor-pointer{
    cursor: pointer;
}
@media (max-width: 500px){
    /* #about .swiper img{
        width: 100%!important;
        height: 100%!important;
    } */
    #about .clients-image{
        width: 60px!important;
        height: 60px!important;
    }
    #about hr{
        border-color: rgba(255, 255, 255, 0.9);
        border-width: 2px;
    }
}